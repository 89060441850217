<template>
  <div v-if="isLoged">
    <b-container>
      <div v-if="user && user.has_changed_password == false">
        <UpdatePassword></UpdatePassword>
      </div>
    </b-container>
    <template v-if="user && user.has_changed_password == true">
      <Dashboard class="dashboard-container"></Dashboard>
    </template>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "DashboardView",
  components: {
    Dashboard: () => import("@/components/users/Dashboard"),
    UpdatePassword: () => import("@/components/users/UpdatePassword"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {},
  watch: {
    $route(to) {
      if (!this.isLoged && to.name == "DashboardView")
        this.$router.push({ name: "Welcome" });
    },
    // isLoged(value) {
    //   if (!value) this.$router.push({ name: "Welcome" });
    // },
  },
  created() {},
};
</script>

<style scoped>
</style>

